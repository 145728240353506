<template>
  <SingleModelLayout
    :model.sync="model"
    :route-back="{
      name: route.index
    }"
    @refresh-model="getModel"
    @delete-model="deleteModel"
  >
    <template #title>
      <TitleSummaryCard
        class="mb-4"
        :edit.sync="edit"
        @edit="startEdit"
        @save="saveModel"
        @discard="discardEdit"
      >
        <template #title>
          <vue-data
            v-model="model.value.name"
            :value="model.value.name"
            :edit="edit"
            :border="false"
          />
        </template>
        <template #subtitle class="opacity-50">
          {{ model.id }}
        </template>
      </TitleSummaryCard>
    </template>
    <template #left>
      <vue-data
        v-model="model.key"
        :value="model.key"
        :edit="edit"
        class="mb-2"
        label="Metadata Key"
      />
      <vue-data
        v-model="model.value.public"
        :value="model.value.public"
        :edit="edit"
        type="checkbox"
        class="mb-2"
        label="Visible to public?"
      />
    </template>
    <template #main>
      <div class="flex flex-wrap w-row-offset -mx-4">
        <div class="w-1/3 px-4">
          <vue-data
            :value="model.images.game_logo ? model.images.game_logo : false"
            :edit="edit"
            :model="model"
            class="mb-4"
            type="file"
            accept="image/png, image/jpeg, image/gif"
            label="Logo Image"
            @update="syncImage($event, 'game_logo')"
            @discard="removeImage('game_logo')"
          />
        </div>
        <div class="w-1/3 px-4">
          <vue-data
            :value="model.images.game_square ? model.images.game_square : false"
            :edit="edit"
            :model="model"
            class="mb-4"
            type="file"
            accept="image/png, image/jpeg, image/gif"
            label="Square Image"
            @update="syncImage($event, 'game_square')"
            @discard="removeImage('game_square')"
          />
        </div>
        <div class="w-1/3 px-4">
          <vue-data
            :value="model.images.game_banner ? model.images.game_banner : false"
            :edit="edit"
            :model="model"
            class="mb-4"
            type="file"
            accept="image/png, image/jpeg, image/gif"
            label="Banner Image"
            @update="syncImage($event, 'game_banner')"
            @discard="removeImage('game_banner')"
          />
        </div>
        <div class="w-full px-4">
          <section class="border-t border-gray-400 pt-4 mt-2 mb-8">
            <vue-data
              v-model="model.value.default_video"
              :value="model.value.default_video"
              :edit="edit"
              :model="model"
              :prepend="model.value.default_video ? 'https://www.youtube.com/watch?v=' : null"
              class="mb-4"
              label="Default Video"
              description="Provide a YouTube embed ID to display as a fallback if a specific spec doesn't have a video avaliable, or as a general game showcase trailer."
            />
          </section>
        </div>
        <div class="w-full px-4">
          <section class="border-t border-gray-400 pt-4 mt-2 mb-8">
            <div class="flex flex-col">
              <template v-if="model.value && model.value.specs">
                <table>
                  <thead>
                    <tr class="whitespace-nowrap">
                      <th class="text-left px-2 py-1">CPU</th>
                      <th class="text-left px-2 py-1">GPU</th>
                      <th class="px-2 py-1">Detail</th>
                      <th class="px-2 py-1">Resolution</th>
                      <th class="px-2 py-1">Min FPS</th>
                      <th class="px-2 py-1">Max FPS</th>
                      <th class="px-2 py-1">Average FPS</th>
                      <th class="px-2 py-1">Playable?</th>
                      <th class="px-2 py-1">Video</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(spec, index) in model.value.specs"
                      :key="index"
                      class="odd:bg-gray-200"
                    >
                      <td
                        :class="{
                          'py-1': true,
                          'px-2': !edit
                        }">
                        <vue-select
                          v-if="edit"
                          v-model="spec.cpu"
                          :options="specOptions.cpuProducts"
                          labelKey="name"
                          valueKey="id"
                        />
                        <span v-else class="block">
                          {{ getFriendlyLabel(specOptions.cpuProducts, spec.cpu, 'id', 'name') }}
                        </span>
                      </td>
                      <td
                        :class="{
                          'py-1': true,
                          'px-2': !edit
                        }">
                        <vue-select
                          v-if="edit"
                          v-model="spec.gpu"
                          :options="specOptions.gpuProducts"
                          labelKey="name"
                          valueKey="id"
                        />
                        <span v-else class="block">
                          {{ getFriendlyLabel(specOptions.gpuProducts, spec.gpu, 'id', 'name') }}
                        </span>
                      </td>
                      <td
                        :class="{
                          'text-center py-1': true,
                          'px-2': !edit
                        }">
                        <vue-select
                          v-if="edit"
                          v-model="spec.options.detail"
                          :options="specOptions.detailOptions"
                          labelKey="label"
                          valueKey="key"
                        />
                        <span v-else class="block text-center">
                          {{ getFriendlyLabel(specOptions.detailOptions, spec.options.detail, 'key', 'label') }}
                        </span>
                      </td>
                      <td
                        :class="{
                          'text-center py-1': true,
                          'px-2': !edit
                        }">
                        <vue-select
                          v-if="edit"
                          v-model="spec.options.resolution"
                          :options="specOptions.resolutionOptions"
                          labelKey="label"
                          valueKey="key"
                        />
                        <span v-else class="block text-center">
                          {{ getFriendlyLabel(specOptions.resolutionOptions, spec.options.resolution, 'key', 'label') }}
                        </span>
                      </td>
                      <td
                        :class="{
                          'text-center py-1': true,
                          'px-2': !edit
                        }">
                        <vue-input
                          v-if="edit"
                          v-model="spec.values.min"
                          append="FPS"
                          type="number"
                          min="0"
                        />
                        <span v-else class="block text-center">
                          {{ spec.values.min }} FPS
                        </span>
                      </td>
                      <td
                        :class="{
                          'text-center py-1': true,
                          'px-2': !edit
                        }">
                        <vue-input
                          v-if="edit"
                          v-model="spec.values.max"
                          append="FPS"
                          type="number"
                          min="0"
                        />
                        <span v-else class="block text-center">
                          {{ spec.values.max }} FPS
                        </span>
                      </td>
                      <td
                        :class="{
                          'text-center py-1': true,
                          'px-2': !edit
                        }">
                        <vue-input
                          v-if="edit"
                          v-model="spec.values.average"
                          append="FPS"
                          type="number"
                          min="0"
                        />
                        <span v-else class="block">
                          {{ spec.values.average }} FPS
                        </span>
                      </td>
                      <td
                        :class="{
                          'flex justify-center py-1': true,
                          'px-2': !edit
                        }">
                        <vue-data
                          v-model="spec.info.playable"
                          :value="spec.info.playable"
                          :edit="edit"
                          :border="false"
                          type="checkbox"
                        />
                      </td>
                      <td
                        :class="{
                          'py-1': true,
                          'px-2': !edit
                        }">
                        <vue-data
                          v-model="spec.info.video"
                          :edit="edit"
                          :prepend="spec.video ? 'https://www.youtube.com/watch?v=' : null"
                          prop="video"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
              <div v-else class="text-gray-600 text-center py-4">
                No specs attached yet
              </div>
              <div v-if="edit" class="w-full flex justify-end mt-4">
                <vue-button
                  :icon="['fas', 'plus']"
                  outline
                  sm
                  flip
                  @click="addNewSpec"
                >
                  Add Performance Spec
                </vue-button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </template>
  </SingleModelLayout>
</template>
<script type="text/javascript">
import { find, cloneDeep, filter } from 'lodash'
import SingleModelLayout from '@/components/layouts/SingleModelLayout'
import TitleSummaryCard from '@/components/TitleSummaryCard'

export default {
  name: 'GamesSingle',

  components: {
    SingleModelLayout,
    TitleSummaryCard
  },

  data () {
    return {
      loading: false,
      edit: false,
      model: false,
      modelOld: false,
      files: [],
      specOptions: {
        cpuProducts: [],
        gpuProducts: [],
        detailOptions: [
          { key: 'low', label: 'Low' },
          { key: 'mid', label: 'Medium' },
          { key: 'high', label: 'High' },
          { key: 'max', label: 'Ultra' }
        ],
        resolutionOptions: [
          { key: '1080p', label: '1080p' },
          { key: '1440p', label: '1440p' },
          { key: '4k', label: '4K' }
        ]
      },
      route: {
        apiEndpoint: 'metadata',
        mediaEndpoint: 'metadata',
        index: 'games.index'
      }
    }
  },

  computed: {
    value () {
      return this.model.value ?? {}
    },

    images () {
      return this.model && this.model.images ? this.model.images : {
        game_square: false,
        game_banner: false
      }
    },

    specs () {
      return this.value && this.value.specs ? this.value.specs : []
    }
  },

  created () {
    this.getModel()
    this.getBuildSetProducts()
  },

  methods: {
    getModel () {
      this.loading = true
      this.$api.get(`metadata/${this.$route.params.model}`, {
        params: {
          withImages: true
        }
      })
        .then((res) => {
          this.model = res.data
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    getBuildSetProducts () {
      this.loading = true
      this.$api.get('public/products/custom-build', {
        params: {
          withPivot: true,
          with: ['parts']
        }
      })
        .then((res) => {
          const parts = res.data.parts
          this.specOptions.cpuProducts = filter(parts, { pivot: { assembly_key: 'cpu' } })
          this.specOptions.gpuProducts = filter(parts, { pivot: { assembly_key: 'gpu' } })
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
    },

    syncImage (data, collection) {
      this.files.push({
        collection,
        data
      })
    },

    removeImage (collection) {
      this.files = []
    },

    saveModel () {
      this.loading = true
      this.saveFiles()
      this.$api.put(`${this.route.apiEndpoint}/${this.$route.params.model}`, this.model)
        .then((res) => {
          this.getModel()
          this.edit = false
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    deleteModel () {
      this.loading = true
      this.$api.delete(`${this.route.apiEndpoint}/${this.$route.params.model}`)
        .then((res) => {
          this.$router.push({
            name: this.route.index
          })
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    saveFiles () {
      if (this.files) {
        this.files.forEach((file) => {
          const formData = new FormData()
          formData.append('file', file.data)
          formData.append('model', 'Metadata')
          formData.append('collection', file.collection)
          this.$api.post(`media/upload/${this.route.mediaEndpoint}/${this.$route.params.model}`, formData, {
            'Content-Type': 'multipart/form-data'
          })
            .catch((err) => {
              this.$store.commit('error/addError', err)
            })
        })
      }
    },

    startEdit () {
      this.modelOld = cloneDeep(this.model)
    },

    discardEdit () {
      this.model = this.modelOld
      this.modelOld = false
      this.edit = false
    },

    addNewSpec () {
      if (typeof this.model.value.specs === 'undefined') {
        this.$set(this.model.value, 'specs', [])
      }
      this.model.value.specs.push({
        cpu: null,
        gpu: null,
        options: {
          detail: null,
          resolution: null
        },
        info: {
          playable: true,
          video: null
        },
        values: {
          min: 0,
          max: 0,
          average: 0
        }
      })
    },

    getFriendlyLabel (source, value, valueKey = 'id', labelKey = 'name') {
      const data = find(source, { [valueKey]: value })
      return typeof data !== 'undefined' ? data.name || data.label || '(No Value)' : '(No Value)'
    }
  }
}
</script>
